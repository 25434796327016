<template>
  <div @keydown="onChange" @click="onChange" @change="onChange">
    <span v-if="state.is_duplicated" class="invalid-feedback">{{ $t('Duplicate exception') }}</span>
    <CRow v-if="!index" class="no-gutters">
      <CCol col="3">{{ $t('Building') }}</CCol>
      <CCol col="2">{{ $t('Month') }}</CCol>
      <CCol col="2">{{ $t('Value') }}</CCol>
      <CCol col="1">{{ $t('# Fee') }}</CCol>
      <CCol col="2">{{ $t('Observations') }}</CCol>
      <CCol col="1" />
    </CRow>
    <CRow class="no-gutters" :class="{ 'is-duplicated': state.is_duplicated }">
      <CCol col="3">
        <AMultiSelect :readonly="is_disabled" :horizontal="{ input: 'col-sm-12' }" :name="`exceptions.$each.${index}.building_id`" v-model="state.building_id" :options="buildings" :isValid="isValid('building_id')" :errors="getErrors('building_id')" />
      </CCol>
      <CCol col="2">
        <AMultiSelect :readonly="is_disabled" :horizontal="{ input: 'col-sm-12' }" :name="`exceptions.$each.${index}.month_id`" v-model="state.month_id" :options="available_months" :isValid="isValid('month_id')" :errors="getErrors('month_id')" />
      </CCol>
      <CCol col="2">
        <AInput :readonly="is_disabled" :horizontal="{ input: 'col-sm-12' }" type="number" :name="`exceptions.$each.${index}.value`" placeholder="Value" v-model="state.value" />
      </CCol>
      <CCol col="1">
        <AInput :readonly="is_disabled" :horizontal="{ input: 'col-sm-12' }" :name="`exceptions.$each.${index}.number_fee`" placeholder="# Fee" v-model="state.number_fee" />
      </CCol>
      <CCol col="3">
        <AInput :readonly="is_disabled" :horizontal="{ input: 'col-sm-12' }" :name="`exceptions.$each.${index}.observations`" placeholder="Observations" v-model="state.observations" />
      </CCol>
      <CCol col="1">
        <button :disabled="is_disabled" type="button" role="button" class="input-group-text" @click="onRemove"><CIcon :name="is_disabled ? 'cil-circle' : 'cil-x'" /></button>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import dataMixin from '@/app/views/_mixins/data-mixin'

export default {
  name: 'ExtraFeeException',
  mixins: [dataMixin],
  props: {
    exception: {
      type: Object,
      required: true
    },
    validation: {
      type: Array,
      default: () => ['', false, false]
    },
    index: {
      type: Number,
      required: true
    },
    lists: {
      type: Object,
      required: true
    },
    months: {
      type: Array,
      required: true
    },
    buildings: {
      type: Array,
      required: true
    },
    periods: {
      type: Object,
      required: true
    }
  },
  watch: {
    exception(val) {
      this.state = val
    },
    months(val) {
      if (this.state.month_id && !val.find(item => item.id === this.state.month_id)) {
        this.$set(this.state, 'month_id', null)
        this.$emit('exceptionChanged', this.state)
      }
    },
    buildings(val) {
      if (this.state.building_id && !val.find(item => item.id === this.state.building_id)) {
        this.$set(this.state, 'building_id', null)
        this.$emit('exceptionChanged', this.state)
      }
    }
  },
  computed: {
    is_disabled() {
      return !!this.state.id && this.periods.FAC.month_id > this.state.month_id
    },
    available_months() {
      if (this.state.id) return this.getSelectedMonths(this.lists.months, [this.state.month_id], this.months)
      return this.months.filter(item => item.id >= this.periods.FAC.month_id)
    }
  },
  data() {
    return {
      state: { ...this.exception }
    }
  },
  mounted() {
    this.onChange(null)
  },
  methods: {
    onSubmit(e) {
      //console.log('SUBMIT')
    },
    onChange(e) {
      this.$emit('exceptionChanged', this.state, e)
    },
    onRemove() {
      this.$emit('removeException', this.index)
    },
    isValid(field) {
      if (!this.validation[1]) return true
      return this.validation[1](this.validation[0] + field)
    },
    getErrors(field) {
      if (!this.validation[2]) return []
      return this.validation[2](this.validation[0] + field)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group-text {
  height: calc(1.5em + 0.75rem + 2px);
}
.is-duplicated {
  border-left: 4px solid red;
}
</style>
